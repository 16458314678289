import "./main.scss";
import { AnimatePresence, motion } from "framer-motion";

function Header() {
  return (
    <motion.div
      className="header"
      transition={{
        ease: [0, 0.2, 0.8, 1],
        duration: 1,
      }}
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
      }}
    >
      <div className="intro">
        <p>
          <span className="caps">Audrey Chow</span> is a San Francisco-based
          designer working in product, web, and graphic design.
        </p>
        <p>
          Originally from Vancouver, Canada, I specialize in crafting interfaces
          that are both beautiful and functional, bespoke and familiar. I'm
          currently a designer at{" "}
          <a href="https://www.contrary.com/" target="_blank">
            Contrary
          </a>
          , focusing on building tools for venture.
        </p>
        <p>
          On the side, I study type design at{" "}
          <a
            href="https://letterformarchive.org/type-west-in-person/"
            target="_blank"
          >
            TypeWest
          </a>{" "}
          and spend my free time meditating through movement and art.
        </p>
      </div>
      <div className="columns text-sm">
        <div className="col experience">
          <h2 className="col-title">Experience</h2>
          <div className="row">
            <div className="year">2023–25</div>
            <div className="item">
              <a href="https://www.contrary.com/" target="blank">
                Contrary
              </a>
            </div>
          </div>
          <div className="row">
            <div className="year">2022</div>
            <div className="item">
              <a href="https://www.daybreak.studio/" target="blank">
                Daybreak Studio
              </a>
            </div>
          </div>
          <div className="row">
            <div className="year">2020–21</div>
            <div className="item">
              <a href="https://curiko.ca" target="blank">
                Curiko
              </a>
            </div>
          </div>
        </div>
        <div className="col education">
          <h2 className="col-title">Education</h2>
          <div className="row">
            <div className="year">2025</div>
            <div className="item">
              <a
                href="https://letterformarchive.org/type-west-in-person/"
                target="blank"
              >
                TypeWest
              </a>
            </div>
          </div>
          <div className="row">
            <div className="year">2023</div>
            <div className="item">
              <a href="https://www.sfu.ca/siat.html" target="blank">
                Simon Fraser University<br></br>
                <span className="sub-item">
                  BS. Interactive Arts and Technology
                </span>
              </a>
            </div>
          </div>
          <div className="row">
            <div className="year">2022</div>
            <div className="item">
              <a href="https://2022.sfuitaliadesign.com/" target="blank">
                italiaDesign Field School
              </a>
            </div>
          </div>
        </div>
        <div className="col more">
            <div className="col-title">Say Hello</div>
            <div className="item"><a href="mailto: audreykchow@gmail.com">audreykchow@gmail.com</a></div>
            <div className="resume"><a href="resume2025.pdf" target="_blank">Full CV</a><img class="arrow" src="arrow.svg"></img></div>
        </div>
      </div>
    </motion.div>
  );
}

export default Header;
