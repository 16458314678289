import "./main.scss";
import React, { useState, useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";

function Project({ id, title, description, extra, image, metadata, nda }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false); // Track if content is expanded
  const descriptionRef = useRef(null); // Reference to the description container

  const changeImage = (direction) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentImageIndex((prevIndex) => {
        if (direction === "next") {
          return (prevIndex + 1) % image.length;
        } else {
          return (prevIndex - 1 + image.length) % image.length;
        }
      });
      setIsTransitioning(false);
    }, 200);
  };

  const toggleContent = () => {
    setIsExpanded((prevState) => !prevState);
  };

  useEffect(() => {
    if (descriptionRef.current && extra) {
      const paragraphs = descriptionRef.current.querySelectorAll("p");
      const lastParagraph = paragraphs[paragraphs.length - 1];

      // Remove any existing content-toggle spans
      const existingToggle =
        descriptionRef.current.querySelector(".content-toggle");
      if (existingToggle) {
        existingToggle.remove();
      }

      // Create and append the toggle button
      if (lastParagraph) {
        const toggleSpan = document.createElement("span");
        toggleSpan.className = "content-toggle";
        toggleSpan.innerHTML = isExpanded ? "Read&nbsp;less" : "Read&nbsp;more";
        toggleSpan.onclick = toggleContent;
        lastParagraph.appendChild(toggleSpan);
      }
    }
  }, [isExpanded, description, extra]);

  return (
    <motion.div
      className="project"
      id={id}
      transition={{
        ease: [0, 0.2, 0.8, 1],
        duration: 1,
      }}
      initial={{
        opacity: 0,
        y: 12,
      }}
      whileInView={{
        opacity: 1,
        y: 0,
      }}
    >
      <div className="project-carousel">
        {image[currentImageIndex].type === "video" ? (
          <video
            src={image[currentImageIndex].src}
            className={`project-video ${isTransitioning ? "transitory" : ""}`}
            controls={false}
            autoPlay
            loop
            muted
            playsInline
            loading="lazy"
          />
        ) : (
          <img
            src={image[currentImageIndex].src}
            alt={title}
            className={`project-image ${isTransitioning ? "transitory" : ""}`}
          />
        )}

        {image.length > 1 && (
          <>
            <div
              className="carousel-left"
              style={{
                cursor: "w-resize",
                position: "absolute",
                left: 0,
                top: 0,
                bottom: 0,
                width: "50%",
                zIndex: 2,
              }}
              onClick={() => changeImage("prev")}
            />
            <div
              className="carousel-right"
              style={{
                cursor: "e-resize",
                position: "absolute",
                right: 0,
                top: 0,
                bottom: 0,
                width: "50%",
                zIndex: 2,
              }}
              onClick={() => changeImage("next")}
            />
          </>
        )}
        {image.length > 1 && (
          <div className="project-pagination">
            {currentImageIndex + 1} of {image.length}
          </div>
        )}
        {nda && (
          <div className="lock">
            <img src="lock.svg" alt="a white lock icon for nda project"></img>
          </div>
        )}
      </div>

      <div className="project-text">
        <div className="project-header">
          <h1 className="project-title">{title}</h1>
          {nda && <h1 className="project-nda">NDA</h1>}
        </div>
        <div className="project-description" ref={descriptionRef}>
          <p dangerouslySetInnerHTML={{ __html: description }} />
          {isExpanded && extra && (
            <motion.p
              className="project-extra"
              initial={{ opacity: 0 }} // Start invisible and slightly below
              animate={{ opacity: 1 }} // Fade in and move to position
              exit={{ opacity: 0 }} // Fade out when hidden
              transition={{ duration: 0.3 }} // Adjust the duration of the animation
            >
              {Array.isArray(extra) ? (
                extra.map((paragraph, index) => <p key={index} dangerouslySetInnerHTML={{ __html: paragraph}}/>)
              ) : (
                <p dangerouslySetInnerHTML={{ __html: extra }}/>
              )}
            </motion.p>
          )}
        </div>
        <div className="project-metadata">
          {metadata &&
            metadata.map(([key, value], index) => (
              <div key={index} className="metadata">
                <div className="metadata-header">{key}</div>
                <div className="metadata-value">
                  {key === "Collaborators" && Array.isArray(value)
                    ? value.map((collaborator, idx) => (
                        <span key={idx}>
                          <a
                            href={collaborator.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="collaborator"
                          >
                            {collaborator.name}
                          </a>
                          <br></br>
                          {idx < value.length - 1}
                        </span>
                      ))
                    : Array.isArray(value)
                    ? value.map((item, idx) => <div key={idx}>{item}</div>)
                    : value}
                </div>
              </div>
            ))}
        </div>
      </div>
    </motion.div>
  );
}

export default Project;
