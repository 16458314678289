import React, { useEffect, useState } from "react";
import "./anchors.scss";

function AnchorItem({ id, title }) {
  const [isInView, setIsInView] = useState(false);
  let timeoutId = null; // To track the timeout for removing blur
  let isScrolling = false; // To prevent multiple scroll actions at once

  const getScrollDurationCap = () => {
    // If the device width is less than 480px, it's considered mobile
    return window.innerWidth < 480 ? 350 : 800; // Mobile cap 350ms, Desktop cap 650ms
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      { threshold: 0.4 } // Trigger when 40% of the element is in view
    );

    const target = document.getElementById(id);
    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, [id]);

  const handleClick = (e) => {
    if (isScrolling) return; // Prevent overlapping scrolls
    isScrolling = true;

    e.preventDefault();

    const allProjects = document.querySelectorAll(".project");
    allProjects.forEach((el) => el.classList.add("blur"));

    const target = document.getElementById(id);
    if (target) {
      const targetImage = target.querySelector("img"); // Adjust to find the correct image
      const imageHeight = targetImage ? targetImage.getBoundingClientRect().height : 0;

      const targetPosition = target.getBoundingClientRect().top + window.pageYOffset;

      const getOffset = () => {
        return window.innerWidth < 480 ? 120 : (window.innerHeight - target.getBoundingClientRect().height) / 2; // Mobile cap 350ms, Desktop cap 650ms
      };

      const offset = (window.innerHeight - imageHeight) / 2;
      const offsetPosition = targetPosition - getOffset();

      // Calculate distance to scroll
      const distance = Math.abs(window.pageYOffset - offsetPosition);

      // Adjust scroll duration dynamically, capping it at a max value
      const scrollDuration = Math.min(distance * 0.3, getScrollDurationCap()); // Adjust multiplier (0.3ms per pixel) and cap (650ms)

      // Smooth scroll to the calculated position
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });

      // Clear previous timeout to avoid stacking effects
      if (timeoutId) clearTimeout(timeoutId);

      // Remove blur after the scroll duration
      timeoutId = setTimeout(() => {
        allProjects.forEach((el) => el.classList.remove("blur"));
        isScrolling = false; // Reset the scroll lock
      }, scrollDuration);
    }
  };

  return (
    <a
      className={`anchor-item ${isInView ? "in-view" : ""}`}
      href={`#${id}`}
      onClick={handleClick}
    >
      <div className="anchor-id">{id}</div>
      <div className="anchor-title">{title}</div>
    </a>
  );
}

export default AnchorItem;
