import "./anchors.scss";
import AnchorItem from "./AnchorItem";
import { AnimatePresence, motion } from "framer-motion";

function AnchorMenu({ projects }) {
  const handleInitialsClick = (e) => {
    e.preventDefault();

    // Add blur effect to all projects
    const allProjects = document.querySelectorAll(".project");
    allProjects.forEach((el) => el.classList.add("blur"));

    // Scroll to the top
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // Remove blur effect after scrolling duration
    const scrollDuration = 800; // Adjust duration as needed
    setTimeout(() => {
      allProjects.forEach((el) => el.classList.remove("blur"));
    }, scrollDuration);
  };

  return (
    <motion.div 
    className="anchor-menu"
    transition={{
      ease: [0, 0.2, 0.8, 1],
      duration: 1,
    }}
    initial={{
      opacity: 0,
    }}
    whileInView={{
      opacity: 1,
    }}
    viewport={{
      once: true
    }}
    >
      <a className="initials" href="#top" onClick={handleInitialsClick}>
        A.C.
      </a>
      {projects.map((project) => (
        <AnchorItem key={project.id} id={project.id} title={project.title} />
      ))}
    </motion.div>
  );
}

export default AnchorMenu;
