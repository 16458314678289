
import Header from "./Header";
import Project from "./Project";
import AnchorMenu from "./AnchorMenu";
import projects from "./projects.json";
import "./main.scss";
import {  motion } from "framer-motion";
import React, { useState, useEffect } from 'react';

function App() {
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const d = new Date();
  const day = weekday[d.getDay()];

  return (

    <div className="App">
      <Header></Header>
      <AnchorMenu projects={projects} />
      <motion.div
        className="gallery"
        initial={{ opacity: 0 }} // Start hidden and slightly below
        animate={{ opacity: 1 }} // Fade in and move to position
        transition={{
          delay: 0.3, // Delay to allow Header to complete its animation
          duration: 1, // Animation duration
          ease: [0, 0.2, 0.8, 1],
        }}
      >
        {projects.map((project) => (
          <Project
            key={project.id}
            title={project.title}
            description={project.description}
            extra={project.extra}
            image={project.image}
            metadata={project.metadata}
            id={project.id}
            nda={project.nda}
          />
        ))}
      </motion.div>
      <motion.div
        className="end"
        transition={{
          ease: [0, 0.2, 0.8, 1],
          duration: 1,
        }}
        initial={{
          opacity: 0,
        }}
        whileInView={{
          opacity: 1,
        }}
      >
        <p>Happy {day}</p>
        <p>
          Always happy to talk — <a href="mailto:audreykchow@gmail.com">audreykchow@gmail.com</a> and @audreykchow on <a href="https://www.instagram.com/audreykchow/" target="_blank" class="underline">IG</a> and <a href="https://www.linkedin.com/in/audreykchow/" target="_blank" class="underline">LinkedIn</a>
        </p>
      </motion.div>
    </div>
  );
}

export default App;
